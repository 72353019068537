/* AdminPage.css */

body {
    font-family: 'Arial', sans-serif;
}

.ant-layout {
    background-color: #f0f2f5;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    background: #001529;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: #fff;
}

.header-content h1 {
    margin: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}

.content-wrapper {
    background: #fff;
    padding: 32px;
    min-height: 360px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.action-buttons {
    display: flex;
    gap: 8px;
}

.ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
}

.ant-modal-header {
    background-color: #001529;
    color: #fff;
    border-radius: 8px 8px 0 0;
}

.ant-modal-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.ant-modal-footer {
    border-radius: 0 0 8px 8px;
}

.ant-table {
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.ant-table-thead > tr > th {
    background-color: #001529;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff; /* Ensure text color is white */
}

.ant-btn-primary {
    background-color: #001529;
    border-color: #001529;
}

.ant-btn-primary:hover {
    background-color: #004080;
    border-color: #004080;
}

.ant-btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
}

.ant-btn-danger:hover {
    background-color: #c0392b;
    border-color: #c0392b;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, 
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    background: #001529;
    color: #fff;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item:hover, 
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title:hover {
    background: #004080;
    color: #fff;
}

.ant-menu-dark .ant-menu-item-selected {
    background: #004080;
}

.ant-menu-dark .ant-menu-item-selected a {
    color: #fff;
}

.ant-layout-footer {
    background: #001529;
    color: #fff;
    text-align: center;
}

/* Customer Modal */
.ant-modal {
    width: 1000px !important; /* Adjust modal width */
}

.ant-modal-body {
    max-height: 500px; /* Adjust modal body max height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.ant-table-wrapper {
    border-radius: 8px;
}

.ant-table-thead th {
    background-color: #001529 !important;
    color: #fff !important; /* Ensure text color is white */
    font-weight: 600;
    text-transform: uppercase;
}

.ant-table-tbody td {
    padding: 12px;
}

.ant-modal-close {
    color: #fff;
}

.ant-modal-footer {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .ant-layout-sider {
        display: none;
    }
    .content-wrapper {
        padding: 16px;
    }
    .header-content h1 {
        font-size: 20px;
    }
}

/* Style for prompt rows */
.ant-table-tbody .prompt-row {
    background-color: #e6f7ff; /* Light blue background for prompt rows */
    color: #000;
    white-space: pre-wrap; /* Preserve whitespace and wrap long text */
    word-wrap: break-word; /* Break long words to fit within the cell */
    overflow-wrap: break-word; /* Break long words to fit within the cell */
}

.ant-table-tbody .prompt-row td {
    padding: 8px;
}

/* Ensure table cells with long content are readable */
.ant-table-tbody td {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
