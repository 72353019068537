/* General Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Site Layout Background */
.site-layout-background {
  background: #fff;
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
}

/* Menu Drawer Button */
.menu-drawer-button {
  display: none; /* Hidden by default */
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .ant-layout-sider {
    display: none !important; /* Hide the sidebar by default on mobile */
  }

  .menu-drawer-button {
    display: block !important; /* Show the drawer button on mobile */
    position: fixed; /* Fixed position at the top-left corner */
    top: 16px;
    left: 16px;
    z-index: 1000; /* Ensure it's above other content */
  }

  .ant-drawer {
    position: fixed !important; /* Ensure drawer is fixed */
    top: 0; /* Align with the top */
    left: 0; /* Align with the left edge */
    z-index: 1000 !important; /* Ensure drawer is above other content */
    display: block !important; /* Show the drawer on mobile */
  }
}

/* Desktop View Adjustments */
@media (min-width: 769px) {
  .ant-layout-sider {
    display: block !important; /* Ensure the sidebar is shown on desktop */
  }

  .menu-drawer-button {
    display: none !important; /* Hide the drawer button on desktop */
  }

  .ant-drawer {
    display: none !important; /* Ensure drawer is hidden on desktop */
  }
}

/* Language Switcher */
.language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.language-switcher button {
  margin-left: 10px;
}

/* Welcome Message */
.welcome-message {
  text-align: center;
  margin-top: 20%;
}

.welcome-message h2 {
  font-size: 2rem;
}

.welcome-message p {
  font-size: 1.2rem;
  margin: 20px 0;
}

.welcome-message button {
  margin: 10px;
}
