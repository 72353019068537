.pricing-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.pricing-header {
  text-align: center;
  margin-bottom: 50px;
}

.pricing-subtext {
  font-size: 1.2rem;
  color: #777;
}

.back-button {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.pricing-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pricing-card {
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.pricing-card:hover {
  transform: translateY(-10px);
}

.pricing-amount {
  color: #1890ff;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.feature-item {
  font-size: 1.1rem;
  margin: 10px 0;
}

.check-icon {
  color: #52c41a;
  margin-right: 10px;
}

.subscribe-button {
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.addon-subtext {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #777;
}
